<template>
  <div id="container">
    <section id="mypage">
      <div class="wrap">
        <div class="cts_box">
          <h2 class="sub_ttl"><span>塾生クラス変更予約のキャンセル</span></h2>
          <p class="lead_txt">
            現在予約されている塾生クラス変更をキャンセルします。<br>
            現在の予約内容をご確認の上、変更予約を取り消す場合は画面下の「クラス変更をキャンセルする」ボタンを押してください。
          </p>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">現在予約されている変更内容</h3>
          <div class="df_table">
            <dl class="flex">
              <dt>現在のクラスとお支払い方法</dt>
              <dd>
                {{ current_user.rank_name }}
                <template v-if="current_user.plan === 'plan_basic'">+ 四季報オンラインベーシック</template>
                <template v-if="current_user.plan === 'plan_premium'">+ 四季報オンラインプレミアム</template>
              </dd>
            </dl>
            <dl class="flex">
              <dt>変更後のクラス</dt>
              <dd>{{ current_user.change_rank }}</dd>
            </dl>
            <dl class="flex">
              <dt>変更後のお支払い方法</dt>
              <dd>{{ current_user.change_rank_payment_monthly ? '月額払い' : '年額払い' }}</dd>
            </dl>
            <dl class="flex" v-if="false">
              <dt>四季報ONLINE優待プラン</dt>
              <dd>変更後のクラスに「プレミアムプラン」が含まれます。</dd>
            </dl>
          </div>
        </div>

        <div class="btn_wrap">
          <p class="alnC sp_alnL mb10">この変更予約をキャンセルする場合はこちらのボタンを押してください。</p>
          <a href="javascript:void(0)" class="submit light arrow large sp_h_auto" @click="cancelRankChange">クラス変更をキャンセルする</a>
        </div>

        <div class="btn_wrap mt40">
          <p class="alnC sp_alnL mb10">キャンセルを取りやめる場合はこちらのボタンを押してください。</p>
          <router-link :to="{name: 'UsersCustomerAccount'}" class="submit light skeleton large arrow back">マイページにもどる</router-link>
        </div>

      </div>
    </section>

    <div id="b_listBox">
      <ul id="b_list">
        <li>
          <router-link to="/">トップページ</router-link>
        </li>
        <li>
          <router-link :to="{name: 'UsersCustomerAccount'}">マイページ</router-link>
        </li>
        <li>塾生クラス変更予約のキャンセル</li>
      </ul>
    </div>

  </div>
</template>

<script>

import Libraries from '@/components/Libraries'

export default {
  mixins: [Libraries],
  data() {
    return {}
  },
  methods: {
    cancelRankChange() {
      if (!confirm('クラスの変更申請をキャンセルしますか？')) {
        return
      }
      // ランク変更キャンセル
      this.axios
          .post(`${this.env.api_base_url}users/cancel_change_rank.json`)
          .then(() => {
            this.$router.push({name: 'UsersCustomerRankChangeCancelFinish'})
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
          })

    }
  }
}
</script>
